<!--
 * @Description: 系统管理  运营商管理 operatorManagement
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-04-21 20:02:24
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm"
               class="searchArea">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="运营商名称">
              <el-input v-model="searchForm.companyName"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="运营商类型">
              <el-select v-model="searchForm.companyType"
                         placeholder="全部">
                <el-option v-for="item in companyTypeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="运营商地址">
              <el-input v-model="searchForm.address"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="handleClickSearch">查 询</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="resetForm">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>运营商管理</span>
        <el-row class="tableTitleButton">
          <el-button type="success"
                     @click="handleClickAdd">新 增</el-button>
        </el-row>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :config="tableConfig"
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <!-- <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template> -->
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="4">
                    <el-button @click="handleClickDetail(scope.row)"
                               type="text"
                               size="medium">详情</el-button>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="text"
                               @click="handleClickEdit(scope.row)"
                               size="medium">修改</el-button>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="text"
                               size="medium"
                               @click="handleClickDelete(scope.row)">删除</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 新增 -->
    <el-dialog title="新增"
               :visible.sync="addDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="addFormList"
               :rules="rules"
               :inline="true"
               ref="addFormList"
               label-width="150px"
               size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="运营商编号"
                          prop="companyId">
              <el-input v-model="addFormList.companyId"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运营商名称"
                          prop="companyName">
              <el-input v-model="addFormList.companyName"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="运营商描述"
                          prop="companyDesc">
              <el-input v-model="addFormList.companyDesc"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运营商类型"
                          prop="companyType">
              <el-select clearable
                         v-model="addFormList.companyType"
                         class="dt-form-width"
                         placeholder="请选择">
                <el-option v-for="(item, index) in companyTypeList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="companyContact">
              <el-input v-model="addFormList.companyContact"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="contactPhoneNumber">
              <el-input v-model="addFormList.contactPhoneNumber"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="地址"
                          prop="address">
              <el-input v-model="addFormList.address"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="注册时间"
                          label-width="150px"
                          prop="joinTime">
              <el-date-picker v-model="addFormList.joinTime"
                              style="width:230px"
                              placeholder="请选择"
                              type="date"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="handleClickAddConfirm('addFormList')">确 定</el-button>
          <el-button type="info"
                     class="popBtn"
                     @click="addDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog title="修改"
               :visible.sync="editDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="editFormList"
               :rules="rules"
               :inline="true"
               ref="editFormList"
               label-width="150px"
               size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="运营商编号"
                          prop="companyId">
              <el-input v-model="editFormList.companyId"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运营商名称"
                          prop="companyName">
              <el-input v-model="editFormList.companyName"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="运营商描述"
                          prop="companyDesc">
              <el-input v-model="editFormList.companyDesc"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运营商类型"
                          prop="companyType">
              <el-select clearable
                         v-model="editFormList.companyType"
                         class="dt-form-width"
                         placeholder="请选择">
                <el-option v-for="(item, index) in companyTypeList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="companyContact">
              <el-input v-model="editFormList.companyContact"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="contactPhoneNumber">
              <el-input v-model="editFormList.contactPhoneNumber"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="地址"
                          prop="address">
              <el-input v-model="editFormList.address"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="注册时间"
                          label-width="150px"
                          prop="joinTime">
              <el-date-picker v-model="editFormList.joinTime"
                              style="width:230px"
                              placeholder="请选择"
                              type="date"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="handleClickEditConfirm('editFormList')">确 定</el-button>
          <el-button type="info"
                     class="popBtn"
                     @click="editDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog title="详情"
               :visible.sync="detailDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="detailFormList"
               :inline="true"
               ref="detailFormList"
               label-width="150px"
               size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="运营商编号"
                          prop="companyId">
              <el-input v-model="detailFormList.companyId"
                        readonly
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运营商名称"
                          prop="companyName">
              <el-input v-model="detailFormList.companyName"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="运营商描述"
                          prop="companyDesc">
              <el-input v-model="detailFormList.companyDesc"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运营商类型"
                          prop="companyType">
              <el-select clearable
                         v-model="detailFormList.companyType"
                         disabled
                         class="dt-form-width"
                         placeholder="请选择">
                <el-option v-for="(item, index) in companyTypeList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="companyContact">
              <el-input v-model="detailFormList.companyContact"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="contactPhoneNumber">
              <el-input v-model="detailFormList.contactPhoneNumber"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="地址"
                          prop="address">
              <el-input v-model="detailFormList.address"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="注册时间"
                          label-width="150px"
                          prop="joinTime">
              <el-date-picker v-model="detailFormList.joinTime"
                              style="width:230px"
                              disabled
                              placeholder="请选择"
                              type="date"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="info"
                     class="popBtn"
                     @click="detailDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  computed: {},
  data () {
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: 'companyName',
          label: '运营商名称',
        },
        {
          prop: 'companyTypeDesc',
          label: '运营商类型',
        },
        {
          prop: 'address',
          label: '运营商地址',
        },
        {
          prop: 'joinTime',
          label: '注册时间',
        },
        {
          prop: 'companyContact',
          label: '注册人',
        }
      ],
      tableList: {
        list: [],
      },
      //查询表单
      searchForm: {
      },
      pageNum: 1,
      pageSize: 15,// 分页参数

      addFormList: {},// 新增表单
      addDialogVisible: false, // 新增弹窗

      detailFormList: {},// 详情表单
      detailDialogVisible: false, // 详情弹窗

      editFormList: {},// 详情表单
      editDialogVisible: false, //修改弹窗

      rules: {
        companyId: [{ required: true, message: '请输入', trigger: 'blur' }],
        companyName: [{ required: true, message: '请输入', trigger: 'blur' }],
        companyDesc: [{ required: true, message: '请输入', trigger: 'blur' }],
        companyContact: [{ required: true, message: '请输入', trigger: 'blur' }],
        contactPhoneNumber: [{ pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号', trigger: 'blur' }],
        address: [{ required: true, message: '请输入', trigger: 'blur' }],
        companyType: [{ required: true, message: '请选择', trigger: 'change' }],
        joinTime: [{ required: true, message: '请选择', trigger: 'blur' }],
      },

      companyTypeList: [
        { name: "企业", code: "1" },
        { name: "非企业/个人", code: "2" }
      ], // 运营商类型

    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryTableList()
  },
  //方法集合
  methods: {
    // 初始化数据
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$operatorManagement.queryCompanyList(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    //搜索
    handleClickSearch () {
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.queryTableList()
    },
    //重置
    resetForm () {
      this.searchForm = {}
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.queryTableList()
    },
    // 新增
    handleClickAdd () {
      this.addFormList = {}
      this.addDialogVisible = true
    },
    // 新增确认
    handleClickAddConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$operatorManagement.addCompany(this.addFormList).then((response) => {
            if (response.resultCode == 2000) {
              this.$message({ message: '新增成功', type: 'success' });
              this.addDialogVisible = false
              this.queryTableList()
            }
          })
        }
      })
    },
    // 详情
    handleClickDetail (row) {
      this.detailFormList = row
      this.detailDialogVisible = true
    },
    // 修改
    handleClickEdit (row) {
      this.editFormList = {}
      this.editFormList = row
      this.editDialogVisible = true
    },
    // 修改确认
    handleClickEditConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$operatorManagement.updateCompany(this.editFormList).then((response) => {
            if (response.resultCode == 2000) {
              this.$message({ message: '修改成功', type: 'success' });
              this.editDialogVisible = false
              this.queryTableList()
            }
          })
        }
      })
    },
    // 删除
    handleClickDelete (row) {
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$operatorManagement.deleteCompany([{ companyId: row.companyId }]).then(res => {
          if (res.resultCode == 2000) {
            this.$message({ message: '删除成功', type: 'success' });
            this.queryTableList()
          }
        })
      });
    },
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-top: 20px;
      padding-left: 110px;
    }
    .buttonArea {
      display: flex;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        display: flex;
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-form-width {
  width: 230px;
}
.lineOne {
  margin-top: 25px;
  .title {
    display: inline-block;
    font-size: 16px;
    color: #70aee8;
    line-height: 18px;
  }
  .content {
    color: #ffffff;
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
</style>


